import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, AIMutations } from './types';
// import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, AIMutations);

export default {
  [_mutations.SET_GENERATED_COMPLAINT](state, complaint) {
    state.generatedComplaint = complaint;
  },
  [_mutations.SET_GENERATED_CAUSE](state, cause) {
    state.generatedCause = cause;
  },
  [_mutations.SET_GENERATED_CORRECTION](state, correction) {
    state.generatedCorrection = correction;
  }
};
