import moment from 'moment';

export const FORM_STAGES = {
  SELECT_BRANCH: 0,
  EDIT_HEADER: 1,
  CREATING_SO: 2,
  EDIT_SO: 3,
  ADDING_JOBS: 4,
  SAVING_SO: 5,
  REMOVING_JOB: 6,
  CREATING_OTHER: 7,
  ADDING_JOBS_OTHER: 8,
  SCHEDULING_SERVICE: 9,
  VIEW_SO: 99
};

export const ITEM_TYPES = {
  LABOR: 590,
  PART: 591,
  CORE: 593,
  SUBLET: 595,
  MISC: 594,
  COMMENT: 596
};

export const PART_TYPES = {
  HAS_CORE: 472,
  CORE: 468
};
export const JOBS = {
  SELECT_BRANCH: 0,
  EDIT_HEADER: 1,
  CREATING: 2,
  EDIT: 3,
  SAVING: 4
};
export const SCOPES = {
  IN_SCOPE: 'INSCOP',
  OUT_SCOPE: 'OUTSCO'
};
export const LIFTGATE_JOB_ID = '055-000-000';
export const TIRE_REPLACEMENT_JOB_ID = '&TIRERNR';

export const JOBLABORTIMECLOCK = {
  isAssignedTech: false,
  isPunchedIn: false,
  isInAttendance: false,
  isComplete: false,
  isActive: false,
  canStart: false,
  canWait: false,
  canHold: false,
  canComplete: false,
  showJobLaborTimeClock: false,
  hoursActual: '00:00:00',
  laborPunches: [],
  timestampSVSLS: '',
  timestampSVSLSOPS: '',
  timestampSVSLSITM: ''
};

export const getDefaultState = () => {
  const nowDate = moment().toDate();
  const dateExpire = moment().startOf('day').add(30, 'day').toDate();
  const defaultEstimorder = {
    serviceOrderId: null,
    invoiceServiceOrderId: null,
    estimateId: null,
    customer: {},
    billToCustomer: {},
    newBillToCustomer: null,
    // Header
    branchId: null,
    branchName: null,
    salesTypeId: null,
    customerId: null,
    invoiced: null,
    licensePlate: null,
    mileage: null,
    engineHours: null,
    repairStatusId: null,
    fleetUnitId: null,
    vin: null,
    // Date + Times
    scheduleDate: null,
    dateSchedule: nowDate,
    dateUnitArrive: nowDate,
    datePromise: null,
    dateNotify: null,
    dateNotifyDiag: null,
    dateAuthorize: null,
    dateActualRepair: null,
    dateExpire: dateExpire,
    dateOpen: nowDate,
    dateCreate: nowDate,
    dateUpdate: null,
    // Breakdown
    repairPriorityId: null,
    repairSiteId: null,
    repairReasonId: null,

    warrantySubmissionId: null,

    attributes: [],

    jobs: {},
    oldJobs: {},
    workflow: {},
    repairContact: {},
    attachments: [],
    auditResults: [],
    auditRunError: null,
    // pricing
    subtotal: null,
    shopSupplies: null,
    epaCharge: null,
    salesTax: null,
    total: null,

    //service request
    hasServiceRequest: false,
    hasEstimateLinesOnServiceOrder: false,

    unitServiceOrderAppointmentId: null,
    dateAppointment: null,
    isEstimateWithApprovedJobs: false,
    //feature flag
    featureFlagPartsRequest: false,
    featureFlagTimeClock: false,
    //po changes
    poUpdatedByName: null,
    poUpdatedByType: null,
    datePoUpdated: null,
    //epay
    ePayRequests: []
  };

  const defaultEstimate = {
    isEstimate: true,
    ...defaultEstimorder,
    requestApproval: {
      approver: null,
      recipients: [],
      datePromise: null
    }
  };

  const defaultServiceOrder = {
    isEstimate: false,
    ...defaultEstimorder,
    unitReady: {
      recipients: []
    }
  };

  return {
    estimorder: 'estimate',
    formStage: FORM_STAGES.SELECT_BRANCH,
    estimate: {
      ...defaultEstimate
    },
    oldestimate: {
      ...defaultEstimate
    },
    serviceOrder: {
      ...defaultServiceOrder
    },
    oldserviceOrder: {
      ...defaultServiceOrder
    },
    appointmentUnitServiceOrders: [],
    // Lookups
    calculatingTaxes: false,
    customerSpecificRepairReasons: [],
    branchSpecificMiscCodes: [],
    partList: [],
    availableParts: [],
    requestedPart: {},
    laborCodesList: [],
    techList: [],
    estimateSearchResults: [],
    serviceOrderSearchResults: [],
    customerAlerts: [],
    customerAlert: [],
    billToCustomerAlerts: [],
    billToCustomerAlert: [],
    hasInScopeLiftgateJob: false,
    isOpenServiceOrder: false
  };
};

export default {
  ...getDefaultState()
};
