export const getDefaultState = () => {
  return {
    generatedComplaint: null,
    generatedCause: null,
    generatedCorrection: null
  };
};

export default {
  ...getDefaultState()
};
