import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, AIGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, AIGetters);

export default {
  [_getters.GET_GENERATED_COMPLAINT]: state => {
    return state.generatedComplaint;
  },
  [_getters.GET_GENERATED_CAUSE]: state => {
    return state.generatedCause;
  },
  [_getters.GET_GENERATED_CORRECTION]: state => {
    return state.generatedCorrection;
  }
};
