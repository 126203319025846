import axios from 'axios';

const AI_PATH_ENDING = '/api/ai';
const THREE_CS_PATH = '/three-cs';

class AIService {
  async generateThreeCs(parameters) {
    const url = `${AI_PATH_ENDING}${THREE_CS_PATH}`;
    const threeCs = await axios.post(url, parameters);
    return threeCs;
  }
}

export default new AIService(); // singleton object
