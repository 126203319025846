export const namespace = 'serviceOrder/';

export const ServiceOrderGetters = {
  GET_SO_SEARCH_RESULTS: namespace + 'getSoSearchResults',
  GET_EST_SEARCH_RESULTS: namespace + 'getEstSearchResults',
  GET_CALCULATING_TAXES: namespace + 'getCalculatingTaxes',
  // SO Level
  GET_SERVICE_ORDER: namespace + 'getServiceOrder',
  GET_SERVICE_ORDER_AUDIT_RESULTS: namespace + 'getServiceOrderAuditResults',
  GET_SERVICE_ORDER_TO_SAVE: namespace + 'getServiceOrderToSave',
  GET_SALES_TYPE_ID: namespace + 'getSalesTypeId',
  GET_PURCHASE_ORDER: namespace + 'getPurchaseOrder',
  GET_JOBS: namespace + 'getJobs',
  GET_CURRENT_OLD_JOBS: namespace + 'getCurrentOldJobs',
  GET_FORM_STAGE: namespace + 'getFormStage',
  GET_BRANCH_ID: namespace + 'getBranchId',
  GET_REPAIR_PRIORITY: namespace + 'getRepairPriority',
  GET_REPAIR_SITE: namespace + 'getRepairSite',
  GET_REPAIR_REASON: namespace + 'getRepairReason',
  GET_REPAIR_STATUS: namespace + 'getRepairStatus',
  GET_CUSTOMER_SPECIFIC_REPAIR_REASONS: namespace + 'getCustomerSpecificRepairReasons',
  GET_BRANCH_SPECIFIC_MISC_CODES: namespace + 'getBranchSpecificMiscCodes',
  GET_IS_ESTIMATE: namespace + 'getIsEstimate',
  GET_ESTIMATE_ID: namespace + 'getEstimateId',
  GET_READONLY: namespace + 'getReadOnly',
  HAS_ESTIMORDER_ERROR: namespace + 'hasEstimorderError',
  HAS_CHANGES: namespace + 'hasChanges',
  GET_OLD_ESTIMORDER: namespace + 'getOldEstimorder',
  GET_SERVICE_ORDER_ID: namespace + 'getServiceOrderId',
  GET_INVOICE_SERVICE_ORDER_ID: namespace + 'getInvoiceServiceOrderId',
  GET_UNIT_READY: namespace + 'getUnitReady',
  GET_REPAIR_CONTACT: namespace + 'getRepairContact',
  GET_CURRENT_ASSIGNMENT: namespace + 'getCurrentAssignment',
  HAS_IN_SCOPE_LIFTGATE_JOB: namespace + 'hasInScopeLiftgateJob',
  GET_CUSTOMER_ALERTS: namespace + 'getCustomerAlerts',
  GET_CUSTOMER_ALERT: namespace + 'getCustomerAlert',
  GET_BILL_TO_CUSTOMER_ALERTS: namespace + 'getBillToCustomerAlerts',
  GET_BILL_TO_CUSTOMER_ALERT: namespace + 'getBillToCustomerAlert',
  GET_BILL_TO_CUSTOMER: namespace + 'getBillToCustomer',
  GET_NEW_BILL_TO_CUSTOMER: namespace + 'getNewBillToCustomer',

  // Job Level
  GET_JOB: namespace + 'getJob',
  GET_ESTIMORDER_DISPLAY_TEXT: namespace + 'getEstimorderDisplayText',
  GET_MISC_LINES: namespace + 'getMiscLines',
  GET_PARTS: namespace + 'getParts',
  GET_AVAILABLE_PARTS: namespace + 'getAvailableParts',
  GET_REQUESTED_PART: namespace + 'getRequestedPart',
  GET_COMMENT_LINES: namespace + 'getCommentLines',
  GET_SUBLET_LINES: namespace + 'getSubletLines',
  GET_INSPECTIONS: namespace + 'getInspections',
  GET_COMPLAINT: namespace + 'getComplaint',
  GET_CAUSE: namespace + 'getCause',
  GET_CORRECTION: namespace + 'getCorrection',
  GET_THREE_CS_EMP_ID_APPROVED: namespace + 'getThreeCsEmpIdApproved',
  GET_JOB_REPAIR_REASON_ID: namespace + 'getJobRepairReasonId',
  GET_VMRS_CODE_BY_JOB: namespace + 'getVMRSCodeByJob',
  GET_TECH_REMARKS: namespace + 'getTechRemarks',
  GET_SHOW_DETAILS: namespace + 'getShowDetails',
  GET_TECH_LIST: namespace + 'getTechList',
  GET_DEFAULT_TECH: namespace + 'getDefaultTech',
  HAS_LABOR_OR_SUBLET: namespace + 'hasLaborOrSublet',
  GET_CUSTOMER: namespace + 'getCustomer',
  GET_LICENSE_PLATE: namespace + 'getLicensePlate',
  GET_MILEAGE: namespace + 'getMileage',
  GET_ENGINE_HOURS: namespace + 'getEngineHours',
  GET_DATE_OPEN: namespace + 'getDateOpen',
  GET_DATE_SCHEDULE: namespace + 'getDateSchedule',
  GET_DATE_UNIT_ARRIVE: namespace + 'getDateUnitArrive',
  GET_DATE_PROMISE: namespace + 'getDatePromise',
  GET_DATE_NOTIFY: namespace + 'getDateNotify',
  GET_DATE_NOTIFY_DIAG: namespace + 'getDateNotifyDiag',
  GET_DATE_AUTHORIZE: namespace + 'getDateAuthorize',
  GET_DATE_ACTUAL_REPAIR: namespace + 'getDateActualRepair',
  GET_DATE_EXPIRE: namespace + 'getDateExpire',
  GET_DATE_CREATE: namespace + 'getDateCreate',
  GET_LABOR_CODES_LIST: namespace + 'getLaborCodesList',
  HAS_OTHER_ESTIMORDER: namespace + 'hasOtherEstimorder',
  GET_WORKFLOW: namespace + 'getWorkflow',
  GET_REQUEST_APPROVAL: namespace + 'getRequestApproval',
  GET_APPOINTMENT_UNIT_SERVICE_ORDERS: namespace + 'getAppointmentUnitServiceOrders',
  HAS_CHANGED_JOB_STATUS: namespace + 'hasChangedJobStatus',
  GET_AUDIT_RUN_ERROR: namespace + 'getAuditRunError',
  // GET_ALLOW_E_PAY: namespace + 'getAllowEPay',
  // GET_ALLOW_E_PAY_IN_PERSON: namespace + 'getAllowEPayInPerson',
  GET_IS_OPEN_SERVICE_ORDER: namespace + 'getIsOpenServiceOrder',
  GET_JOB_LABOR_TECH_REMARKS_HAS_CHANGES: namespace + 'getJobLaborTechRemarksHasChanges'
};

export const ServiceOrderActions = {
  RESET_STATE: namespace + 'resetState',
  SWITCH_STAGE: namespace + 'switchStage',
  FETCH_SERVICE_ORDER: namespace + 'fetchServiceOrder',
  FETCH_SERVICE_ORDER_PRICING: namespace + 'fetchServiceOrderPricing',
  FETCH_CUSTOMER_SPECIFIC_REPAIR_REASONS: namespace + 'fetchCustomerSpecificRepairReasons',
  FETCH_BRANCH_SPECIFIC_MISC_CODES: namespace + 'fetchBranchSpecificMiscCodes',
  FETCH_LINKED_SERVICE_ORDER: namespace + 'fetchLinkedServiceOrder',
  UPDATE_SERVICE_ORDER: namespace + 'updateServiceOrder',
  CALCULATE_SO_TAXES: namespace + 'calcuateSoTaxes',
  UPDATE_BILL_TO_CUSTOMER: namespace + 'updateBillToCustomer',
  SET_SERVICE_ORDER: namespace + 'setServiceOrder',
  DEFAULT_PROP: namespace + 'defaultProp',
  SEARCH_SERVICE_ORDERS: namespace + 'searchServiceOrders',
  SEARCH_ESTIMATES: namespace + 'searchEstimates',
  EXPORT: namespace + 'export',
  FETCH_CUSTOMER_ALERTS: namespace + 'fetchCustomerAlerts',
  FETCH_BILL_TO_CUSTOMER_ALERTS: namespace + 'fetchBillToCustomerAlerts',
  ASSIGN_JOBS: namespace + 'assignJobs',
  ADD_LABOR_LINE: namespace + 'addLaborLine',
  DUPLICATE_LABOR_LINE: namespace + 'duplicateLaborLine',
  FETCH_TECH_LIST: namespace + 'fetchTechList',
  SEARCH_PARTS: namespace + 'searchParts',
  SELECT_PART: namespace + 'selectPart',
  FETCH_PARTS: namespace + 'fetchParts',
  FETCH_PART_PRICE: namespace + 'fetchPartPrice',
  SET_PART_PRICE: namespace + 'setPartPrice',
  FETCH_CUSTOMER: namespace + 'fetchCustomer',
  UPDATE_QTY_ON_HAND: namespace + 'updateQtyOnHand',
  CLONE_SERVICE_ORDER: namespace + 'cloneServiceOrder',
  CREATE_SERVICE_ORDER: namespace + 'createServiceOrder',
  CREATE_LABOR_TIME_PUNCH_IN: namespace + 'createLaborTimePunchIn',
  CREATE_LABOR_TIME_PUNCH_OUT: namespace + 'createLaborTimePunchOut',
  COMPLETE_UNIT_SERVICE_ORDER_LABOR: namespace + 'completeUnitServiceOrderLabor',
  CREATE_OTHER_ESTIMORDER: namespace + 'createOtherEstimorder',
  ADD_JOBS_TO_SERVICE_ORDER: namespace + 'addJobsToServiceOrder',
  SEARCH_LABOR_CODES: namespace + 'searchLaborCodes',
  SELECT_LABOR_CODES: namespace + 'selectLaborCodes',
  REMOVE_JOB: namespace + 'removeJob',
  FIND_MATCHING_OPERATION: namespace + 'findMatchingOperation',
  FETCH_WORKFLOW: namespace + 'fetchWorkflow',
  ASSIGN_EMPLOYEE_TO_SERVICE_ORDER: namespace + 'assignEmployeeToServiceOrder',
  SEND_APPROVAL_REQUEST: namespace + 'sendApprovalRequest',
  SEND_UNIT_READY: namespace + 'sendUnitReady',
  FETCH_REPAIR_CONTACT: namespace + 'fetchRepairContact',
  UPDATE_REPAIR_CONTACT: namespace + 'updateRepairContact',
  PRINT_FORM: namespace + 'printServiceOrder',
  FETCH_APPOINTMENT_UNIT_SERVICE_ORDERS: namespace + 'fetchAppointmentUnitServiceOrders',
  SAVE_LABOR_TECH_REMARKS: namespace + 'saveLaborTechRemarks',
  FETCH_UPDATE_JOB_LABOR_TECH_REMARKS: namespace + 'fetchJobLaborTechRemarks',
  SEND_PAYMENT_REQUEST: namespace + 'sendPaymentRequest',
  SEND_CARD_PAYMENT_REQUEST: namespace + 'sendCardPaymentRequest',
  VOID_PAYMENT_REQUEST: namespace + 'voidPaymentRequest',
  REFUND_PAYMENT_REQUEST: namespace + 'refundPaymentRequest'
};

export const ServiceOrderMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_TIMESTAMP: namespace + 'setTimestamp',
  SET_JOB_TIMESTAMP: namespace + 'setJobTimestamp',
  SET_BRANCH: namespace + 'setBranch',
  SET_REPAIR_PRIORITY: namespace + 'setRepairPriority',
  SET_REPAIR_SITE: namespace + 'setRepairSite',
  SET_REPAIR_REASON: namespace + 'setRepairReason',
  SET_REPAIR_STATUS: namespace + 'setRepairStatus',
  ADD_JOBS: namespace + 'addJobs',
  ADD_OLD_JOBS: namespace + 'addOldJobs',
  REMOVE_JOB: namespace + 'removeJob',
  UPDATE_JOB_OPERATION_STATUS: namespace + 'updateJobOperationStatus',
  SET_JOB_SALES_TYPE: namespace + 'setJobSalesType',
  ADD_JOB_DETAILS: namespace + 'addJobDetails',
  ADD_LABOR_LINE: namespace + 'addLaborLine',
  SET_CUSTOMER_SPECIFIC_REPAIR_REASONS: namespace + 'setCustomerSpecificRepairReasons',
  SET_BRANCH_SPECIFIC_MISC_CODES: namespace + 'setBranchSpecificMiscCodes',
  SET_LABOR_CODES_LIST: namespace + 'setLaborCodesList',
  SET_LABOR_CODE: namespace + 'setLaborCode',
  SET_LABOR_DESCRIPTION: namespace + 'setLaborDes',
  SET_LABOR_PRICE: namespace + 'setLaborPrice',
  SET_LABOR_SUGGESTED_PRICE: namespace + 'setLaborSuggestedPrice',
  SET_HOURS_BILL: namespace + 'setHoursBill',
  SET_HOURS_FLAT: namespace + 'setHoursFlat',
  SET_TECH_LIST: namespace + 'setTechList',
  SET_TECH: namespace + 'setTech',
  SET_COMPLAINT: namespace + 'setComplaint',
  SET_CAUSE: namespace + 'setCause',
  SET_CORRECTION: namespace + 'setCorrection',
  SET_THREE_CS_INPUT_COMPLAINT: namespace + 'setThreeCsInputComplaint',
  SET_THREE_CS_EMP_ID_APPROVED: namespace + 'setThreeCsEmpIdApproved',
  SET_THREE_CS_DATE_TIME_APPROVED: namespace + 'setThreeCsDateTimeApproved',
  SET_JOB_REPAIR_REASON_ID: namespace + 'setJobRepairReasonId',
  REMOVE_LABOR_LINE: namespace + 'removeLaborLine',
  ADD_PART: namespace + 'addPart',
  REMOVE_PART: namespace + 'removePart',
  UPDATE_PART_PROP: namespace + 'updatePartProp',
  SET_PART: namespace + 'setPart',
  SET_REQUESTED_PART: namespace + 'setRequestedPart',
  SET_PART_LIST: namespace + 'setPartList',
  SET_PART_DESCRIPTION: namespace + 'setPartDes',
  SET_PART_QUOTE: namespace + 'setPartQuote',
  SET_AVAILABLE_PARTS: namespace + 'setAvailableParts',
  SET_PART_QTY_ON_HAND: namespace + 'setPartQtyOnHand',
  SET_PART_QTY: namespace + 'setPartQty',
  SET_PART_PRICE: namespace + 'setPartPrice',
  SET_PART_MARGIN: namespace + 'setPartMargin',
  SET_PART_SEARCH: namespace + 'togglePartSearch',
  TOGGLE_PART_LOADING: namespace + 'togglePartLoading',
  SET_SALES_TYPE_ID: namespace + 'setSalesTypeId',
  SET_DEFAULT_SALES_TYPE_ID: namespace + 'setDefaultSalesTypeId',
  SET_CUSTOMER: namespace + 'setCustomer',
  ADD_SUBLET_LINE: namespace + 'addSubletLine',
  REMOVE_SUBLET_LINE: namespace + 'removeSubletLine',
  SET_SUBLET_CODE: namespace + 'setSubletCode',
  SET_SUBLET_PRICE: namespace + 'setSubletPrice',
  SET_SUBLET_COST: namespace + 'setSubletCost',
  SET_SUBLET_DESCRIPTION: namespace + 'setSubletDes',
  SET_SUBLET_QTY: namespace + 'setSubletQty',
  SET_SUBLET_SEARCH: namespace + 'setSubletSearch',
  ADD_MISC_LINE: namespace + 'addMiscLine',
  REMOVE_MISC_LINE: namespace + 'removeMiscLine',
  SET_MISC_CODE: namespace + 'setMiscCode',
  SET_MISC_DESCRIPTION: namespace + 'setMiscDes',
  SET_MISC_PRICE: namespace + 'setMiscPrice',
  SET_MISC_QTY: namespace + 'setMiscQty',
  SET_MISC_SEARCH: namespace + 'setMiscSearch',
  SET_COMMENT_CODE: namespace + 'setCommentCode',
  SET_COMMENT_TEXT: namespace + 'setCommentText',
  ADD_COMMENT_LINE: namespace + 'addCommentLine',
  REMOVE_COMMENT_LINE: namespace + 'removeCommentLine',
  ADD_INSPECTION: namespace + 'addInspection',
  REMOVE_INSPECTION: namespace + 'removeInspection',
  SET_INSPECTION: namespace + 'setInspection',
  SET_VMRS_CODE: namespace + 'setVMRSCode',
  SET_COMMENT_SEARCH: namespace + 'setCommentSearch',
  SET_PROP: namespace + 'setProp',
  SET_HAS_IN_SCOPE_LIFTGATE_JOB: namespace + 'hasInScopeLiftgateJob',
  SET_ATTRIBUTE: namespace + 'setAttribute',
  ADD_ATTRIBUTES_TO_FORM: namespace + 'addAttributesToForm',
  SET_SERVICE_ORDER_WITHOUT_JOBS: namespace + 'setServiceOrderWithoutJobs',
  SET_JOBS: namespace + 'setJobs',
  SET_OLD_JOBS: namespace + 'setOldJobs',
  SET_SHOW_DETAILS: namespace + 'setShowDetails',
  SET_SHOW_CLOCK: namespace + 'setShowClocks',
  SET_FORM_STAGE: namespace + 'setFormStage',
  SET_SO_SEARCH_RESULTS: namespace + 'setSoSearchResults',
  SET_EST_SEARCH_RESULTS: namespace + 'setEstSearchResults',
  SET_ESTIMORDER: namespace + 'setEstimorder',
  SYNC_PO: namespace + 'syncPo',
  SET_ESTIMORDER_ERROR: namespace + 'setEstimorderError',
  SET_ATTACHMENTS: namespace + 'setAttachments',
  SET_OLD_ESTIMORDER: namespace + 'setOldEstimorder',
  SET_OLD_ESTIMORDER_PROP: namespace + 'setOldEstimorderProp',
  SET_CUSTOMER_ALERTS: namespace + 'setCustomerAlerts',
  SET_CUSTOMER_ALERT: namespace + 'setCustomerAlert',
  SET_BILL_TO_CUSTOMER_ALERTS: namespace + 'setBillToCustomerAlerts',
  SET_BILL_TO_CUSTOMER_ALERT: namespace + 'setBillToCustomerAlert',
  SET_APPOINTMENT_UNIT_SERVICE_ORDERS: namespace + 'setAppointmentUnitServiceOrders',
  SET_TAXES: namespace + 'setTaxes',
  SET_CALCULATING_TAXES: namespace + 'setCalculatingTaxes',
  SET_SERVICE_ORDER_PRICING: namespace + 'setServiceOrderPricing',
  SET_LABOR_TIME_CLOCK_DATA: namespace + 'setLaborTimeClockData',
  SET_AUDIT_RUN_ERROR: namespace + 'setAuditRunError',
  SET_IS_OPEN_SERVICE_ORDER: namespace + 'setIsOpenServiceOrder',
  SET_REPAIR_CONTACT: namespace + 'setRepairContact',
  SET_SERVICE_ORDER_LABOR_TECH_REMARKS: namespace + 'setServiceOrderLaborTechRemarks',
  SET_JOB_LABOR_TECH_REMARKS: namespace + 'setJobLaborTechRemarks',
  RESET_JOB_LABOR_TECH_REMARKS: namespace + 'resetJobLaborTechRemarks'
};
