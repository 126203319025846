<template>
  <vams-collapse :ref="`collapse-${jobKey}`" title="Tire Replacement">
    <b-row v-if="axleConfiguration" class="d-flex no-gutters">
      <div v-if="loading" class="text-center"><b-spinner></b-spinner></div>
      <b-col v-else cols="3" class="mr-5">
        <b-overlay :show="saving">
          <b-col cols="10" class="axle-section-headers">Axle Configuration</b-col>
          <v-select
            id="axle"
            :value="axleConfiguration"
            :disabled="true"
            label="description"
            :options="axleConfigurationsList"
            :clearable="false"
          />
          <b-col v-if="!readonly" cols="12" class="axle-section-headers">Tire to Replace</b-col>
          <b-col v-if="readonly" cols="12" class="axle-section-headers">Tire</b-col>
          <v-select
            id="tire"
            v-model="tireConfigurationId"
            label="description"
            :options="tireConfigurations"
            :reduce="t => t.tireConfigurationId"
            :clearable="false"
            select-on-tab
            @input="setTireConfigId($event)"
          >
            <template #option="tire">
              <span class="text-uppercase">{{ tire.description }}</span>
              <span>&nbsp;</span>
              <span v-if="tire.isSavedTire" align="right">
                <font-awesome-icon v-show="tire.isSavedTire" icon="check" class="text-success" />
              </span>
            </template>
            <template #selected-option="tire">
              <span class="text-uppercase">{{ tire.description }}</span>
              <span>&nbsp;</span>
              <span v-if="tire.isSavedTire" align="right">
                <font-awesome-icon v-show="tire.isSavedTire" icon="check" class="text-success" />
              </span>
            </template>
          </v-select>
          <div v-if="axleConfiguration">
            <b-col cols="12" style="text-align: center">
              <img
                class="pt-2"
                :src="`/images/axles/${axleConfiguration.imageName}`"
                width="150px"
                height="auto"
                style="min-height: 1px"
                :alt="axleConfiguration.description"
              />
            </b-col>
          </div>
        </b-overlay>
      </b-col>
      <b-col v-if="isTireSelected && !switchingTires" cols="8">
        <b-overlay :show="saving">
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding-left: 5vw">
              <div class="tire-section-headers">{{ tireConfiguration.description }}</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div class="tire-section-headers">Old Tire</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div class="tire-section-headers">New Tire</div>
            </b-col>
          </b-row>
          <b-row class="d-flex">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Tire Size</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireSize || NA }}</div>
              <v-select
                v-else
                id="oldTireSize"
                v-model="oldTireSize"
                label="description"
                :options="tireSizesList"
                :reduce="t => t.description"
                :clearable="true"
                select-on-tab
              ></v-select>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireSize || NA }}</div>

              <v-select
                v-else
                id="newTireSize"
                v-model="newTireSize"
                label="description"
                :options="tireSizesList"
                :reduce="t => t.description"
                :clearable="true"
                select-on-tab
              ></v-select>
            </b-col>
          </b-row>
          <b-row class="d-flex">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Type</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireTypeDescription || NA }}</div>

              <v-select
                v-else
                id="oldTireType"
                v-model="oldTireType"
                :reduce="t => t.tireTypeId"
                label="description"
                :options="tireTypesList"
                select-on-tab
              />
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireTypeDescription || NA }}</div>

              <v-select
                v-else
                id="newTireType"
                v-model="newTireType"
                :reduce="t => t.tireTypeId"
                label="description"
                :options="tireTypesList"
                select-on-tab
              />
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Load Range</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireLoadRangeDescription || NA }}</div>

              <v-select
                v-else
                id="oldTireLoadRange"
                v-model="oldTireLoadRange"
                label="description"
                :options="loadRangesList"
                select-on-tab
              />
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireLoadRangeDescription || NA }}</div>

              <v-select
                v-else
                id="newTireLoadRange"
                v-model="newTireLoadRange"
                label="description"
                :options="loadRangesList"
                select-on-tab
              />
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Manufacturer</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireManufacturerDescription || NA }}</div>

              <v-select
                v-else
                id="oldTireManufacturer"
                v-model="oldTireManufacturer"
                :reduce="t => t.tireManufacturerId"
                label="description"
                :options="tireManufacturersList"
                select-on-tab
              />
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireManufacturerDescription || NA }}</div>

              <v-select
                v-else
                id="newTireManufacturer"
                v-model="newTireManufacturer"
                :reduce="t => t.tireManufacturerId"
                label="description"
                :options="tireManufacturersList"
                select-on-tab
              />
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Tire Model</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireModel || NA }}</div>

              <b-input v-else v-model="oldTireModel"></b-input>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireModel || NA }}</div>

              <b-input v-else v-model="newTireModel"></b-input>
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">DOT #</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireDotNumber || NA }}</div>

              <b-input v-else v-model="oldTireDotNumber"></b-input>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireDotNumber || NA }}</div>

              <b-input v-else v-model="newTireDotNumber"></b-input>
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Tread Depth</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireTreadDepthDescription || NA }}</div>

              <v-select
                v-else
                id="oldTireTreadDepth"
                v-model="oldTireTreadDepth"
                :reduce="t => t.treadDepthId"
                label="description"
                :options="treadDepthsList"
                select-on-tab
              />
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireTreadDepthDescription || NA }}</div>

              <v-select
                v-else
                id="newTireTreadDepth"
                v-model="newTireTreadDepth"
                :reduce="t => t.treadDepthId"
                label="description"
                :options="treadDepthsList"
                select-on-tab
              />
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">PSI</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTirePSI || NA }}</div>

              <b-input v-else v-model="oldTirePSI" type="number"></b-input>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTirePSI || NA }}</div>

              <b-input v-else v-model="newTirePSI" type="number"></b-input>
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Install Miles</div>
            </b-col>
            <!--WHAT TO DO WHEN THEY DO NOT HAVE MILAGE DATA?? IS IT JUST FREE TEXT-->
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireInstallMiles || NA }}</div>

              <b-input v-else v-model="oldTireInstallMiles"></b-input>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireInstallMiles || NA }}</div>

              <b-input v-else v-model="newTireInstallMiles" disabled></b-input>
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Install Date</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ oldTireInstallDate | date }}</div>

              <date-time-input
                v-else
                id="oldInstallDate"
                v-model="oldTireInstallDate"
                :disabled-dates="oldDisabledDates"
                hide-time
                :required="false"
                label=""
              ></date-time-input>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ newTireInstallDate | date }}</div>

              <date-time-input
                v-else
                id="newInstallDate"
                v-model="newTireInstallDate"
                hide-time
                label=""
              ></date-time-input>
            </b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col cols="4" style="padding: 5px">
              <div class="tire-properties-headers">Reason for Replacement</div>
            </b-col>
            <b-col cols="4" style="padding: 5px">
              <div v-if="readonly">{{ reasonForReplacementDescription || NA }}</div>
              <v-select
                v-else
                id="reasonForReplacement"
                v-model="reasonForReplacement"
                label="description"
                :reduce="t => t.tireReplacementReasonId"
                :options="replacementReasonsList"
                :clearable="false"
                select-on-tab
              />
            </b-col>
            <b-col cols="4"></b-col>
          </b-row>
          <b-row class="d-flex no-gutters">
            <b-col v-if="!readonly" style="padding: 5px" class="d-flex justify-content-end">
              <b-button class="mr-3" variant="primary" :disabled="saving" @click="saveTire">
                <div v-show="saving">
                  <b-spinner small></b-spinner>
                  Saving...
                </div>
                <div v-show="!saving && !readonly">Save {{ tireConfiguration.description }}</div>
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row v-else class="tire-section-headers" cols="9">
      Please Select an Axle Configuration on the Unit Details Page
    </b-row>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      message="You have unsaved changes. What would you like to do?"
      :has-changes="hasChanges"
      stay-on-page
      continue-btn-text="Continue with Tire"
    ></unsaved-changes-modal>
  </vams-collapse>
</template>

<script>
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';

import { mapMutations, mapGetters, mapActions } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
import { TireGetters, TireActions, TireMutations } from '@/shared/store/tire/types';
import { UnitGetters, UnitMutations } from '@/shared/store/unit/types';

import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import vSelect from 'vue-select';

import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import moment from 'moment';

export default {
  name: 'TireReplacementComponent',
  components: {
    'vams-collapse': CollapseComponent,
    'date-time-input': DateTimeInput,
    'unsaved-changes-modal': UnsavedChangesModal,
    vSelect
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NA: 'N/A',
      saving: false,
      tireConfigId: null,
      previousTireConfigId: null,
      switchingTires: false,
      skip: false
    };
  },
  computed: {
    ...mapGetters({
      axleConfigurationsList: LookupGetters.GET_AXLE_CONFIGURATIONS_LIST,
      tireSizesList: LookupGetters.GET_TIRE_SIZES_LIST,
      tireTypesList: LookupGetters.GET_TIRE_TYPES_LIST,
      loadRangesList: LookupGetters.GET_LOAD_RANGES_LIST,
      tireManufacturersList: LookupGetters.GET_TIRE_MANUFACTURERS_LIST,
      replacementReasonsList: LookupGetters.GET_TIRE_REPLACEMENT_REASONS_LIST,
      treadDepthsList: LookupGetters.GET_TREAD_DEPTHS_LIST,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      unit: UnitGetters.GET_UNIT,
      tireReplacements: TireGetters.GET_TIRE_REPLACEMENTS,
      serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      oldTire: TireGetters.GET_OLD_TIRE,
      dbOldTire: TireGetters.GET_DB_OLD_TIRE,
      dbNewTire: TireGetters.GET_DB_NEW_TIRE,
      newTire: TireGetters.GET_NEW_TIRE
    }),
    ...mapGetters([TireGetters.HAS_CHANGES]),
    isTireSelected() {
      return this.tireConfigId != null;
    },
    axleConfiguration: {
      get() {
        return this.unit.axleConfiguration;
      },
      set(axleConfig) {
        this[UnitMutations.SET_PROP]({ key: 'axleConfiguration', value: axleConfig });
      }
    },
    tireConfigurationId: {
      get() {
        return this.tireConfigId;
      },
      set(value) {
        this.tireConfigId = value;
      }
    },
    tireConfiguration: {
      get() {
        return this.axleConfiguration.tireConfigurations.find(t => t.tireConfigurationId == this.tireConfigId);
      }
    },
    tireConfigurations: {
      get() {
        return this.axleConfiguration.tireConfigurations;
      }
    },
    oldDisabledDates() {
      return { from: this.today };
    },
    newDisabledDates() {
      return { to: this.oldTireInstallDate, from: this.today };
    },
    today() {
      return moment(this.dateCreate).startOf('day').toDate();
    },
    oldTireSize: {
      get() {
        return this.oldTire?.tireSize;
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireSize', value: value });
      }
    },
    newTireSize: {
      get() {
        return this.newTire?.tireSize;
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireSize', value: value });
      }
    },
    oldTireType: {
      get() {
        return this.tireTypesList.find(t => t.tireTypeId == this.oldTire?.tireTypeId);
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireTypeId', value });
      }
    },
    oldTireTypeDescription: {
      get() {
        return this.tireTypesList.find(t => t.tireTypeId == this.oldTire?.tireTypeId)?.description;
      }
    },
    newTireType: {
      get() {
        return this.tireTypesList.find(t => t.tireTypeId == this.newTire?.tireTypeId);
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireTypeId', value });
      }
    },
    newTireTypeDescription: {
      get() {
        return this.tireTypesList.find(t => t.tireTypeId == this.newTire?.tireTypeId)?.description;
      }
    },
    oldTireLoadRange: {
      get() {
        return this.loadRangesList.find(l => l.loadRangeId == this.oldTire?.loadRangeId);
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'loadRangeId', value: value.loadRangeId });
      }
    },
    oldTireLoadRangeDescription: {
      get() {
        return this.loadRangesList.find(l => l.loadRangeId == this.oldTire?.loadRangeId)?.description;
      }
    },
    newTireLoadRange: {
      get() {
        return this.loadRangesList.find(l => l.loadRangeId == this.newTire?.loadRangeId);
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'loadRangeId', value: value.loadRangeId });
      }
    },
    newTireLoadRangeDescription: {
      get() {
        return this.loadRangesList.find(l => l.loadRangeId == this.newTire?.loadRangeId)?.description;
      }
    },
    oldTireManufacturer: {
      get() {
        return this.tireManufacturersList.find(t => t.tireManufacturerId == this.oldTire?.tireManufacturerId);
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireManufacturerId', value });
      }
    },
    oldTireManufacturerDescription: {
      get() {
        return this.tireManufacturersList.find(t => t.tireManufacturerId == this.oldTire?.tireManufacturerId)
          ?.description;
      }
    },
    newTireManufacturer: {
      get() {
        return this.tireManufacturersList.find(t => t.tireManufacturerId == this.newTire?.tireManufacturerId);
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireManufacturerId', value });
      }
    },
    newTireManufacturerDescription: {
      get() {
        return this.tireManufacturersList.find(t => t.tireManufacturerId == this.newTire?.tireManufacturerId)
          ?.description;
      }
    },
    oldTireModel: {
      get() {
        return this.oldTire?.tireModel;
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireModel', value });
      }
    },
    newTireModel: {
      get() {
        return this.newTire?.tireModel;
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireModel', value });
      }
    },
    oldTireDotNumber: {
      get() {
        return this.oldTire?.dotNumber;
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'dotNumber', value });
      }
    },
    newTireDotNumber: {
      get() {
        return this.newTire?.dotNumber;
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'dotNumber', value });
      }
    },
    oldTireTreadDepth: {
      get() {
        return this.treadDepthsList.find(t => t.treadDepthId == this.oldTire?.treadDepthId);
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'treadDepthId', value });
      }
    },
    oldTireTreadDepthDescription: {
      get() {
        return this.treadDepthsList.find(t => t.treadDepthId == this.oldTire?.treadDepthId)?.description;
      }
    },
    newTireTreadDepth: {
      get() {
        return this.treadDepthsList.find(t => t.treadDepthId == this.newTire?.treadDepthId);
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'treadDepthId', value });
      }
    },
    newTireTreadDepthDescription: {
      get() {
        return this.treadDepthsList.find(t => t.treadDepthId == this.newTire?.treadDepthId)?.description;
      }
    },
    oldTirePSI: {
      get() {
        return this.oldTire?.poundsPerSquareInch;
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'poundsPerSquareInch', value });
      }
    },
    newTirePSI: {
      get() {
        return this.newTire?.poundsPerSquareInch;
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'poundsPerSquareInch', value });
      }
    },
    oldTireInstallMiles: {
      get() {
        return this.oldTire?.installMiles;
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'installMiles', value });
      }
    },
    newTireInstallMiles: {
      get() {
        return this.serviceOrder.mileage;
      }
    },
    oldTireInstallDate: {
      get() {
        return this.oldTire?.dateInstall;
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'dateInstall', value });
      }
    },
    newTireInstallDate: {
      get() {
        return this.newTire.dateInstall ? this.newTire.dateInstall : this.today;
      },
      set(value) {
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'dateInstall', value });
      }
    },
    reasonForReplacement: {
      get() {
        return this.replacementReasonsList.find(
          r => r.tireReplacementReasonId == this.oldTire?.tireReplacementReasonId
        );
      },
      set(value) {
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireReplacementReasonId', value });
      }
    },
    reasonForReplacementDescription: {
      get() {
        return this.replacementReasonsList.find(r => r.tireReplacementReasonId == this.oldTire?.tireReplacementReasonId)
          ?.description;
      }
    }
  },
  watch: {
    tireConfigId: {
      handler() {
        if (!this.skip) {
          this.checkForUnsavedChangesAndSwitchTires();
        }
        this.skip = false;
      },
      immediate: true
    },
    tireReplacements: {
      handler() {
        this.setIsTireSaved();
      },
      immediate: true
    }
  },
  async created() {
    if (this.readonly && this.tireReplacements.length == 0) {
      await this[TireActions.FETCH_TIRE_REPLACEMENTS]({
        unitId: this.unit.unitId,
        serviceOrderId: this.serviceOrderId
      });
    }
    this.$emit('created');
  },
  methods: {
    ...mapMutations([
      UnitMutations.SET_PROP,
      TireMutations.SET_OLD_TIRE_PROP,
      TireMutations.SET_OLD_TIRE,
      TireMutations.SET_NEW_TIRE_PROP,
      TireMutations.SET_NEW_TIRE,
      TireMutations.RESET_STATE
    ]),
    ...mapActions([TireActions.FETCH_TIRE_REPLACEMENTS, TireActions.SAVE_UPDATE_TIRE_REPLACEMENTS]),
    hasChanges() {
      return this[TireGetters.HAS_CHANGES];
    },
    setIsTireSaved() {
      this.tireReplacements.forEach(tire => {
        if (
          tire.tireReplacementId >= 0 &&
          tire.mountedServiceOrderId?.substring(0, 10) == this.serviceOrderId?.substring(0, 10)
        ) {
          let t = this.tireConfigurations.find(x => x.tireConfigurationId == tire.tireConfigurationId);
          t.isSavedTire = true;
        }
      });
    },
    setTireConfigId(value) {
      if (this.previousTireConfigId === null) {
        this.previousTireConfigId = this.tireConfigurationId;
      }
      this.tireConfigId = value;
    },
    checkForUnsavedChangesAndSwitchTires() {
      if (this.hasChanges()) {
        this.$refs.UnsavedChangesModal.show(this, this.resetFormAndSwitchTires);
      } else {
        this.switchTires();
      }
    },
    resetFormAndSwitchTires(cont = true) {
      if (cont) {
        this[TireMutations.SET_OLD_TIRE_PROP]({
          key: 'tireConfigurationId',
          value: this.dbOldTire.tireConfigurationId
        });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireSize', value: this.dbOldTire.tireSize });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireTypeId', value: this.dbOldTire.tireTypeId });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'loadRangeId', value: this.dbOldTire.loadRangeId });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireManufactuerId', value: this.dbOldTire.tireManufacturerId });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireModel', value: this.dbOldTire.tireModel });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'dotNumber', value: this.dbOldTire.dotNumber });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'treadDepthId', value: this.dbOldTire.treadDepthId });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'poundPerSquareInch', value: this.dbOldTire.poundPerSquareInch });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'installMiles', value: this.dbOldTire.installMiles });
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'dateInstall', value: this.dbOldTire.dateInstall });
        this[TireMutations.SET_OLD_TIRE_PROP]({
          key: 'tireReplacementReasonId',
          value: this.dbOldTire.tireReplacementReasonId
        });

        this[TireMutations.SET_NEW_TIRE_PROP]({
          key: 'tireConfigurationId',
          value: this.dbNewTire.tireConfigurationId
        });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireSize', value: this.dbNewTire.tireSize });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireTypeId', value: this.dbNewTire.tireTypeId });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'loadRangeId', value: this.dbNewTire.loadRangeId });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireManufactuerId', value: this.dbNewTire.tireManufacturerId });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireModel', value: this.dbNewTire.tireModel });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'dotNumber', value: this.dbNewTire.dotNumber });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'treadDepthId', value: this.dbNewTire.treadDepthId });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'poundPerSquareInch', value: this.dbNewTire.poundPerSquareInch });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'installMiles', value: this.dbNewTire.installMiles });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'dateInstall', value: this.dbNewTire.dateInstall });

        this.switchTires();
      }
      if (!cont) {
        this.skip = true;
        this.tireConfigId = this.previousTireConfigId;
      }
    },
    async switchTires() {
      this.previousTireConfigId = this.tireConfigurationId;
      this.switchingTires = true;
      let newTire = this.tireReplacements.find(
        t => t.tireConfigurationId === this.tireConfigId && t.isNewTire === true
      );
      if (newTire?.tireReplacementId > 0) {
        await this[TireMutations.SET_NEW_TIRE](newTire);
      } else {
        this.resetNewTire();
      }
      let oldTire = this.tireReplacements.find(
        t => t.tireConfigurationId === this.tireConfigId && t.isOldTire === true
      );
      if (oldTire?.tireReplacementId > 0) {
        this[TireMutations.SET_OLD_TIRE](oldTire);
      } else {
        this.resetOldTire();
      }
      this.switchingTires = false;
    },
    resetOldTire() {
      this[TireMutations.SET_OLD_TIRE]({
        tireTypeId: null,
        tireManufacturerId: null,
        dotNumber: null,
        tireModel: null,
        tireReplacementReasonId: null,
        tireSize: null,
        loadRangeId: null,
        treadDepthId: null,
        poundsPerSquareInch: null
      });
    },
    resetNewTire() {
      this[TireMutations.SET_NEW_TIRE]({
        tireTypeId: null,
        tireManufacturerId: null,
        dotNumber: null,
        tireModel: null,
        tireReplacementReasonId: null,
        tireSize: null,
        loadRangeId: null,
        treadDepthId: null,
        poundsPerSquareInch: null
      });
    },
    saveTireOnServiceOrderSave() {
      if (this.hasChanges()) {
        this.saveTire();
      }
    },
    async saveTire() {
      try {
        this.saving = true;
        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'isOldTire', value: true });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'isNewTire', value: true });

        if (this.newTire?.tireReplacementId) {
          this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireReplacementId', value: this.newTire.tireReplacementId });
        }

        if (this.oldTire?.tireReplacementId) {
          this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireReplacementId', value: this.oldTire.tireReplacementId });
        }

        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'tireConfigurationId', value: this.tireConfigId });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'tireConfigurationId', value: this.tireConfigId });

        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'unitId', value: this.unit.unitId });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'unitId', value: this.unit.unitId });

        this[TireMutations.SET_OLD_TIRE_PROP]({ key: 'dismountedServiceOrderId', value: this.serviceOrderId });
        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'mountedServiceOrderId', value: this.serviceOrderId });

        this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'installMiles', value: this.serviceOrder.mileage });

        if (!this.newTire?.dateInstall) {
          this[TireMutations.SET_NEW_TIRE_PROP]({ key: 'dateInstall', value: this.today });
        }
        let parameters = [this.oldTire, this.newTire];
        await this[TireActions.SAVE_UPDATE_TIRE_REPLACEMENTS](parameters);
        await this[TireActions.FETCH_TIRE_REPLACEMENTS]({
          unitId: this.unit.unitId,
          serviceOrderId: this.serviceOrderId
        });
        this.switchTires();
        SuccessService.createSuccessToast(this.$root, 'Tire Replacement saved successfully.');
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to save Tire Replacement data.');
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style>
.axle-section-headers {
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.5rem;
  color: grey;
}
.tire-section-headers {
  font-weight: 600;
  text-transform: uppercase;
}
.tire-properties-headers {
  padding-left: 5vw;
  font-weight: 400;
  text-transform: uppercase;
  color: grey;
}
</style>
