import ai from '@/shared/store/ai';
import attachment from '@/shared/store/attachment';
import breakdown from '@/shared/store/breakdown';
import customer from '@/shared/store/customer';
import config from '@/shared/store/config';
import deferredJob from '@/shared/store/deferred-job';
import dvir from '@/shared/store/dvir';
import inspection from '@/shared/store/inspection';
import invoice from '@/shared/store/invoice';
import lookup from '@/shared/store/lookup';
import note from '@/shared/store/note';
import operationsForecast from '@/shared/store/operations-forecast';
import partsRequest from '@/shared/store/parts-request';
import partsOrder from '@/shared/store/parts-order';

import serviceOrder from '@/shared/store/service-order';
import subletInvoice from '@/shared/store/sublet-invoice';
import tire from '@/shared/store/tire';
import unit from '@/shared/store/unit';
import user from '@/shared/store/user';
import warranty from '@/shared/store/warranty';
import getters from './getters';
import mutations from './mutations';
import state from './state';

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ai,
    attachment,
    breakdown,
    customer,
    config,
    deferredJob,
    dvir,
    inspection,
    invoice,
    lookup,
    note,
    operationsForecast,
    partsOrder,
    partsRequest,
    serviceOrder,
    subletInvoice,
    tire,
    unit,
    user,
    warranty
  },
  state,
  getters,
  mutations,
  strict: process.env.NODE_ENV !== `production`
});
