<template>
  <div>
    <b-modal
      :id="`threeCsModal_${_uid}`"
      :ref="`threeCsModal_${_uid}`"
      size="lg"
      no-close-on-backdrop
      title="Complaint, Cause, and Correction"
      :hide-footer="true"
      lazy
      @hidden="reset"
    >
      <div>
        <div class="my-2">
          <b-form-group label="Complaint:" label-class="col-head" label-for="complaint" class="pb-2">
            <div>
              <b-textarea
                id="complaint"
                v-model="complaint"
                class="overflow-auto rounded"
                size="sm"
                rows="2"
                max-rows="10"
                @keyup="charComplaintCharacterCount()"
              />
              <span class="col-head">{{ totalComplaintCharacter }} / 2000</span>
            </div>
          </b-form-group>
        </div>
        <div class="my-2">
          <b-form-group label="Cause:" label-class="col-head" label-for="cause" class="pb-2">
            <div>
              <b-textarea
                id="cause"
                v-model="cause"
                class="overflow-auto rounded"
                size="sm"
                rows="2"
                max-rows="10"
                @keyup="charCauseCharacterCount()"
              />
              <span class="col-head">{{ totalCauseCharacter }} / 255</span>
            </div>
          </b-form-group>
        </div>
        <div class="my-2">
          <b-form-group label="Correction:" label-class="col-head" label-for="correction" class="pb-2">
            <div>
              <b-textarea
                id="correction"
                v-model="correction"
                class="overflow-auto rounded"
                size="sm"
                rows="5"
                max-rows="15"
                @keyup="charCorrectionCharacterCount()"
              />
              <span class="col-head">{{ totalCorrectionCharacter }} / 4000</span>
            </div>
          </b-form-group>
        </div>

        <div class="warning-text">
          **Once Approved, the text presented will overwrite any existing text in the Complaint, Cause & Correction
          fields on the corresponding job**
        </div>

        <div class="pt-4">
          <b-button variant="primary" @click="approve">Approve</b-button>
          <b-button variant="secondary ml-3" @click="cancel">
            <div>Cancel</div>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ServiceOrderMutations } from '../../../store/service-order/types';
import { UserGetters } from '../../../store/user/types';
import moment from 'moment';
import { AIGetters } from '../../../store/ai/types';

export default {
  name: 'ThreeCsModal',
  components: {},
  props: {
    jobKey: {
      type: String,
      required: true
    },
    inputComplaint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      cause: null,
      complaint: null,
      correction: null
    };
  },
  computed: {
    ...mapGetters({
      user: UserGetters.GET_USER_PROFILE,
      generatedComplaint: AIGetters.GET_GENERATED_COMPLAINT,
      generatedCause: AIGetters.GET_GENERATED_CAUSE,
      generatedCorrection: AIGetters.GET_GENERATED_CORRECTION
    }),
    ...mapGetters([
      AIGetters.GET_GENERATED_COMPLAINT,
      AIGetters.GET_GENERATED_CAUSE,
      AIGetters.GET_GENERATED_CORRECTION
    ]),
    totalComplaintCharacter: {
      get() {
        return this.complaint ? this.complaint.length : 0;
      },
      set(value) {
        return value;
      }
    },
    totalCauseCharacter: {
      get() {
        return this.cause ? this.cause.length : 0;
      },
      set(value) {
        return value;
      }
    },
    totalCorrectionCharacter: {
      get() {
        return this.correction ? this.correction.length : 0;
      },
      set(value) {
        return value;
      }
    }
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.SET_COMPLAINT,
      ServiceOrderMutations.SET_CAUSE,
      ServiceOrderMutations.SET_CORRECTION,
      ServiceOrderMutations.SET_THREE_CS_EMP_ID_APPROVED,
      ServiceOrderMutations.SET_THREE_CS_INPUT_COMPLAINT,
      ServiceOrderMutations.SET_THREE_CS_DATE_TIME_APPROVED
    ]),
    ...mapActions([]),
    async show() {
      this.complaint = this.generatedComplaint;
      this.cause = this.generatedCause;
      this.correction = this.generatedCorrection;
      this.$bvModal.show(`threeCsModal_${this._uid}`);
    },
    async approve() {
      this.user;
      this[ServiceOrderMutations.SET_COMPLAINT]({ jobKey: this.jobKey, complaint: this.complaint });
      this[ServiceOrderMutations.SET_CAUSE]({ jobKey: this.jobKey, cause: this.cause });
      this[ServiceOrderMutations.SET_CORRECTION]({ jobKey: this.jobKey, correction: this.correction });
      this[ServiceOrderMutations.SET_THREE_CS_EMP_ID_APPROVED]({ jobKey: this.jobKey, empId: this.user.employeeId });
      this[ServiceOrderMutations.SET_THREE_CS_INPUT_COMPLAINT]({
        jobKey: this.jobKey,
        inputComplaint: this.inputComplaint
      });
      this[ServiceOrderMutations.SET_THREE_CS_DATE_TIME_APPROVED]({
        jobKey: this.jobKey,
        dateTimeApproved: moment()
      });
      this.$bvModal.hide(`threeCsModal_${this._uid}`);
    },
    reset() {
      this.$bvModal.hide(`threeCsModal_${this._uid}`);
    },
    cancel() {
      this.$bvModal.hide(`threeCsModal_${this._uid}`);
    }
  }
};
</script>
<style>
.warning-text {
  color: red;
  font-size: 1.7vh;
}
</style>
