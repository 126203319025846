export const namespace = 'ai/';

export const AIGetters = {
  GET_GENERATED_COMPLAINT: namespace + 'getGeneratedComplaint',
  GET_GENERATED_CAUSE: namespace + 'getGeneratedCause',
  GET_GENERATED_CORRECTION: namespace + 'getGeneratedCorrection'
};

export const AIActions = {
  GENERATE_THREE_CS: namespace + 'generateThreeCs'
};

export const AIMutations = {
  SET_GENERATED_COMPLAINT: namespace + 'setGeneratedComplaint',
  SET_GENERATED_CAUSE: namespace + 'setGeneratedCause',
  SET_GENERATED_CORRECTION: namespace + 'setGeneratedCorrection'
};
