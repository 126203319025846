import namespaceHelper from '@/shared/helpers/namespace-helper';
import Cloner from '@/shared/helpers/cloner';
import { namespace, ServiceOrderMutations } from './types';
import { getDefaultState, JOBLABORTIMECLOCK } from './state';
import Vue from 'vue';

const _mutations = namespaceHelper.removeNamespace(namespace, ServiceOrderMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_CUSTOMER](state, customer) {
    state[state.estimorder].customer = customer;
    state[state.estimorder].billToCustomer = customer.billToCustomer;
  },
  [_mutations.SET_FORM_STAGE](state, stage) {
    state.formStage = stage;
  },
  [_mutations.SET_ESTIMORDER](state, estimorder) {
    state.estimorder = estimorder;
  },
  [_mutations.SET_SERVICE_ORDER_WITHOUT_JOBS](state, payload) {
    const { serviceOrder, estimorder } = payload;
    const newServiceOrder = { ...state[estimorder] };
    newServiceOrder.customer = serviceOrder.customer;
    newServiceOrder.billToCustomer = serviceOrder.billToCustomer;
    newServiceOrder.isEstimate = serviceOrder.isEstimate;
    newServiceOrder.reviewed = serviceOrder.reviewed;
    newServiceOrder.unitId = serviceOrder.unitId;
    newServiceOrder.fleetUnitId = serviceOrder.fleetUnitId;
    newServiceOrder.vin = serviceOrder.vin;
    newServiceOrder.serviceOrderId = serviceOrder.serviceOrderId;
    newServiceOrder.invoiceServiceOrderId = serviceOrder.invoiceSlsId;
    newServiceOrder.estimateId = serviceOrder.estimateId;
    newServiceOrder.readonly = serviceOrder.readonly;
    newServiceOrder.invoiced = serviceOrder.invoiced;
    newServiceOrder.branchId = serviceOrder.branchId;
    newServiceOrder.branchName = serviceOrder.branchName;
    newServiceOrder.dateSchedule = serviceOrder.dateSchedule;
    newServiceOrder.dateCreate = serviceOrder.dateCreate;
    newServiceOrder.dateUnitArrive = serviceOrder.dateUnitArrive;
    newServiceOrder.dateNotify = serviceOrder.dateNotify;
    newServiceOrder.dateNotifyDiag = serviceOrder.dateNotifyDiag;
    newServiceOrder.datePromise = serviceOrder.datePromise;
    newServiceOrder.dateOpen = serviceOrder.dateOpen;
    newServiceOrder.dateActualRepair = serviceOrder.dateActualRepair;
    newServiceOrder.dateExpire = serviceOrder.dateExpire;
    newServiceOrder.dateInvoice = serviceOrder.dateInvoice;
    newServiceOrder.dateAuthorize = serviceOrder.dateAuthorize;
    newServiceOrder.dateUpdate = serviceOrder.dateUpdate;
    newServiceOrder.subtotal = serviceOrder.subtotal;
    newServiceOrder.jobSubtotal = serviceOrder.jobSubtotal;
    newServiceOrder.shopSupplies = serviceOrder.shopSupplies;
    newServiceOrder.epaCharge = serviceOrder.epaCharge;
    newServiceOrder.salesTax = serviceOrder.salesTax;
    newServiceOrder.total = serviceOrder.total;
    newServiceOrder.repairPriorityId = serviceOrder.repairPriorityId;
    newServiceOrder.repairSiteId = serviceOrder.repairSiteId;
    newServiceOrder.repairReasonId = serviceOrder.repairReasonId;
    newServiceOrder.repairStatusId = serviceOrder.repairStatusId;
    newServiceOrder.customerId = serviceOrder.customerId;
    newServiceOrder.billToCustomerId = serviceOrder.billToCustomerId;
    newServiceOrder.salesTypeId = serviceOrder.salesTypeId;
    newServiceOrder.purchaseOrder = serviceOrder.purchaseOrder;
    newServiceOrder.licensePlate = serviceOrder.licensePlate;
    newServiceOrder.mileage = serviceOrder.mileage;
    newServiceOrder.engineHours = serviceOrder.engineHours;
    newServiceOrder.attachments = serviceOrder.attachments;
    newServiceOrder.workflow = serviceOrder.workflow;
    newServiceOrder.repairContact = serviceOrder.repairContact || {};
    newServiceOrder.timestampSVSLS = serviceOrder.timestampSVSLS;
    newServiceOrder.attributes = serviceOrder.attributes;
    newServiceOrder.warrantySubmissionId = serviceOrder.warrantySubmissionId;
    newServiceOrder.hasServiceRequest = serviceOrder.hasServiceRequest;
    newServiceOrder.hasEstimateLinesOnServiceOrder = serviceOrder.hasEstimateLinesOnServiceOrder;
    newServiceOrder.unitServiceOrderAppointmentId = serviceOrder.appointmentId;
    newServiceOrder.dateAppointment = serviceOrder.dateAppointment;
    if (serviceOrder.breakdownId) newServiceOrder.breakdownId = serviceOrder.breakdownId;
    newServiceOrder.featureFlagPartsRequest = serviceOrder.featureFlagPartsRequest;
    newServiceOrder.featureFlagTimeClock = serviceOrder.featureFlagTimeClock;
    newServiceOrder.poUpdatedByEmail = serviceOrder.poUpdatedByEmail;
    newServiceOrder.poUpdatedByName = serviceOrder.poUpdatedByName;
    newServiceOrder.poUpdatedByType = serviceOrder.poUpdatedByType;
    newServiceOrder.datePoUpdated = serviceOrder.datePoUpdated;
    newServiceOrder.auditResults = serviceOrder.auditResults;
    newServiceOrder.auditRunError = serviceOrder.auditRunError;
    newServiceOrder.allowEPay = serviceOrder.allowEPay;
    newServiceOrder.allowEPayInPerson = serviceOrder.allowEPayInPerson;
    newServiceOrder.allowEPayVoid = serviceOrder.allowEPayVoid;
    newServiceOrder.allowEPayRefund = serviceOrder.allowEPayRefund;
    newServiceOrder.allowAIThreeCs = serviceOrder.allowAIThreeCs;
    newServiceOrder.ePayRequests = serviceOrder.ePayRequests;
    // Data not saved in DB
    if (serviceOrder.requestApproval) newServiceOrder.requestApproval = serviceOrder.requestApproval;
    if (serviceOrder.unitReady) newServiceOrder.unitReady = serviceOrder.unitReady;
    if (serviceOrder.newNote) newServiceOrder.newNote = serviceOrder.newNote;
    Vue.set(state, estimorder, Cloner.deepClone(newServiceOrder));
  },
  [_mutations.SYNC_PO](state, payload) {
    state['estimate'].purchaseOrder = payload.purchaseOrder;
    state['serviceOrder'].purchaseOrder = payload.purchaseOrder;
    state['oldestimate'].purchaseOrder = payload.purchaseOrder;
    state['oldserviceOrder'].purchaseOrder = payload.purchaseOrder;

    state['estimate'].poUpdatedByName = payload.poUpdatedByName;
    state['serviceOrder'].poUpdatedByName = payload.poUpdatedByName;
    state['oldestimate'].poUpdatedByName = payload.poUpdatedByName;
    state['oldserviceOrder'].poUpdatedByName = payload.poUpdatedByName;

    state['estimate'].poUpdatedByType = payload.poUpdatedByType;
    state['serviceOrder'].poUpdatedByType = payload.poUpdatedByType;
    state['oldestimate'].poUpdatedByType = payload.poUpdatedByType;
    state['oldserviceOrder'].poUpdatedByType = payload.poUpdatedByType;

    state['estimate'].datePoUpdated = payload.datePoUpdated;
    state['serviceOrder'].datePoUpdated = payload.datePoUpdated;
    state['oldestimate'].datePoUpdated = payload.datePoUpdated;
    state['oldserviceOrder'].datePoUpdated = payload.datePoUpdated;
  },
  [_mutations.SET_PROP](state, payload) {
    state[state.estimorder][payload.key] = payload.value;
  },
  [_mutations.SET_ATTRIBUTE](state, payload) {
    var attrbute = state[state.estimorder].attributes.find(attr => (attr.attributeId = payload.attributeId));
    Vue.set(attrbute, 'value', payload.value);
  },
  [_mutations.ADD_ATTRIBUTES_TO_FORM](state, attrbutes) {
    state[state.estimorder].attributes = Cloner.deepClone(attrbutes);
    state['old' + state.estimorder].attributes = Cloner.deepClone(attrbutes);
  },
  [_mutations.SET_OLD_ESTIMORDER](state, estimorder) {
    state['old' + estimorder] = Cloner.deepClone(state[estimorder]);
  },
  [_mutations.SET_OLD_ESTIMORDER_PROP](state, { estimorder, key, value }) {
    state['old' + estimorder][key] = Cloner.deepClone(value);
  },
  [_mutations.SET_ESTIMORDER_ERROR](state, payload) {
    const error = payload.error;
    const estimorder = payload.estimorder;
    Vue.set(state[estimorder], '_error', error);
  },
  [_mutations.SET_TIMESTAMP](state, timestamp) {
    state[state.estimorder].timestampSVSLS = timestamp;
    state['old' + state.estimorder].timestampSVSLS = timestamp;
  },
  [_mutations.SET_JOB_TIMESTAMP](state, updatedJobs) {
    for (let job of Object.values(state[state.estimorder].jobs)) {
      const updatedJob = updatedJobs.find(j => j.jobId === job.jobId && j.operationId === job.operationId);
      job.timestampSVSLSOPS = updatedJob.timestampSVSLSOPS;
    }
    for (let job of Object.values(state['old' + state.estimorder].jobs)) {
      const updatedJob = updatedJobs.find(j => j.jobId === job.jobId && j.operationId === job.operationId);
      job.timestampSVSLSOPS = updatedJob.timestampSVSLSOPS;
    }
  },
  // SO Search
  [_mutations.SET_SO_SEARCH_RESULTS](state, results) {
    state.serviceOrderSearchResults = results;
  },
  [_mutations.SET_EST_SEARCH_RESULTS](state, results) {
    state.estimateSearchResults = results;
  },
  // Header
  [_mutations.SET_BRANCH](state, branchId) {
    state[state.estimorder].branchId = branchId;
  },
  [_mutations.SET_SALES_TYPE_ID](state, salesTypeId) {
    state[state.estimorder].salesTypeId = salesTypeId;
  },
  [_mutations.SET_HAS_IN_SCOPE_LIFTGATE_JOB](state, value) {
    state.hasInScopeLiftgateJob = value;
  },
  [_mutations.SET_DEFAULT_SALES_TYPE_ID](state, salesTypeId) {
    state['estimate'].salesTypeId = salesTypeId;
    state['serviceOrder'].salesTypeId = salesTypeId;
    state['oldestimate'].salesTypeId = salesTypeId;
    state['oldserviceOrder'].salesTypeId = salesTypeId;
  },
  [_mutations.SET_REPAIR_PRIORITY](state, repairPriorityId) {
    state[state.estimorder].repairPriorityId = repairPriorityId;
  },
  [_mutations.SET_REPAIR_SITE](state, repairSiteId) {
    state[state.estimorder].repairSiteId = repairSiteId;
  },
  [_mutations.SET_REPAIR_REASON](state, repairReasonId) {
    state[state.estimorder].repairReasonId = repairReasonId;
  },
  [_mutations.SET_REPAIR_STATUS](state, repairStatusId) {
    state[state.estimorder].repairStatusId = repairStatusId;
  },
  [_mutations.SET_CALCULATING_TAXES](state, calculatingTaxes) {
    state.calculatingTaxes = calculatingTaxes;
  },
  [_mutations.SET_SERVICE_ORDER_PRICING](state, soPricing) {
    state['serviceOrder'].epaCharge = soPricing.epaCharge;
    state['serviceOrder'].subtotal = soPricing.subtotal;
    state['serviceOrder'].shopSupplies = soPricing.shopSupplies;
    state['serviceOrder'].salesTax = soPricing.salesTax;
    state['serviceOrder'].total = soPricing.total;
    state['oldserviceOrder'].epaCharge = soPricing.epaCharge;
    state['oldserviceOrder'].subtotal = soPricing.subtotal;
    state['oldserviceOrder'].shopSupplies = soPricing.shopSupplies;
    state['oldserviceOrder'].salesTax = soPricing.salesTax;
    state['oldserviceOrder'].total = soPricing.total;
  },
  [_mutations.SET_TAXES](state, { salesTax, total, timestampSVSLS }) {
    state[state.estimorder].salesTax = salesTax;
    state['old' + state.estimorder].salesTax = salesTax;
    state[state.estimorder].total = total;
    state['old' + state.estimorder].total = total;
    state[state.estimorder].timestampSVSLS = timestampSVSLS;
    state['old' + state.estimorder].timestampSVSLS = timestampSVSLS;
  },

  // Jobs
  [_mutations.SET_JOBS](state, payload) {
    const jobs = payload.jobs;
    const estimorder = payload.estimorder;
    state[estimorder].jobs = jobs;
  },
  [_mutations.SET_OLD_JOBS](state, payload) {
    const estimorder = payload.estimorder;
    const old = Cloner.deepClone(payload.jobs);
    state[estimorder].oldJobs = old;
  },
  [_mutations.ADD_JOBS](state, payload) {
    const newJobs = payload.jobs;
    const estimorder = payload.estimorder;
    state[estimorder].jobs = { ...state[estimorder].jobs, ...newJobs };
  },
  [_mutations.ADD_OLD_JOBS](state, payload) {
    const newJobs = payload.jobs;
    const estimorder = payload.estimorder;
    state[estimorder].oldJobs = { ...state[estimorder].oldJobs, ...newJobs };
  },
  [_mutations.REMOVE_JOB](state, jobKey) {
    Vue.delete(state[state.estimorder].jobs, jobKey);
    Vue.delete(state['old' + state.estimorder].jobs, jobKey);
  },
  [_mutations.ADD_JOB_DETAILS](state, jobKey) {
    const job = state[state.estimorder].jobs[jobKey];
    Vue.set(state[state.estimorder].jobs, jobKey, {
      ...job,
      details: { laborLines: [], subletLines: [], parts: [], miscLines: [], commentLines: [] }
    });
  },
  [_mutations.SET_SHOW_DETAILS](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], '_showDetails', payload._showDetails);
  },
  [_mutations.SET_SHOW_CLOCK](state, payload) {
    Vue.set(
      state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.lbrIndex],
      '_showClock',
      payload._showClock
    );
  },
  [_mutations.SET_COMPLAINT](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'complaint', payload.complaint);
  },
  [_mutations.SET_CAUSE](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'cause', payload.cause);
  },
  [_mutations.SET_CORRECTION](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'correction', payload.correction);
  },
  [_mutations.SET_THREE_CS_EMP_ID_APPROVED](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'aI3CsEmpIdApproved', payload.empId);
  },
  [_mutations.SET_THREE_CS_INPUT_COMPLAINT](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'aI3CsInputComplaint', payload.inputComplaint);
  },
  [_mutations.SET_THREE_CS_DATE_TIME_APPROVED](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'aI3CsDateTimeApproved', payload.dateTimeApproved);
  },
  [_mutations.SET_JOB_REPAIR_REASON_ID](state, { jobKey, repairReasonId }) {
    Vue.set(state[state.estimorder].jobs[jobKey], 'repairReasonId', repairReasonId);
  },
  [_mutations.UPDATE_JOB_OPERATION_STATUS](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'jobOperationStatusId', payload.jobOperationStatusId);
  },
  [_mutations.SET_JOB_SALES_TYPE](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'salesTypeId', payload.salesTypeId);
  },
  [_mutations.SET_SERVICE_ORDER_LABOR_TECH_REMARKS](state, payload) {
    let currentSo = state[state.estimorder];
    let currentJob = currentSo.jobs[payload.jobKey];
    let currentLabor = currentJob.details.laborLines[payload.lbrIndex];
    let latestClockData = currentLabor.jobLaborTimeClock;
    Vue.set(latestClockData, 'techRemarks', payload.techRemarks);
  },
  [_mutations.SET_JOB_LABOR_TECH_REMARKS](state, payload) {
    let oldSo = state['old' + state.estimorder];
    let oldJob = oldSo.jobs[payload.jobKey];
    Vue.set(oldJob, 'techRemarks', payload.techRemarks);

    let currentSo = state[state.estimorder];
    let currentJob = currentSo.jobs[payload.jobKey];
    Vue.set(currentJob, 'techRemarks', payload.techRemarks);
  },
  // Labor Line
  [_mutations.SET_LABOR_TIME_CLOCK_DATA](state, payload) {
    let oldCopyTimeClockData = Object.assign({}, payload.timeClockData);
    let newCopyTimeClockData = Object.assign({}, payload.timeClockData);
    // setting the time stamp for all the impacted records through the "old" object in state
    let oldSo = state['old' + state.estimorder];
    Vue.set(oldSo, 'timestampSVSLS', oldCopyTimeClockData.timestampSVSLS);
    let oldJob = oldSo.jobs[payload.jobKey];
    let oldOldJob = oldSo.oldJobs[payload.jobKey];
    Vue.set(oldJob, 'timestampSVSLSOPS', oldCopyTimeClockData.timestampSVSLSOPS);
    Vue.set(oldOldJob, 'timestampSVSLSOPS', oldCopyTimeClockData.timestampSVSLSOPS);
    let oldlabor = oldJob.details.laborLines[payload.lbrIndex];
    let oldOldLabor = oldOldJob.details.laborLines[payload.lbrIndex];
    Vue.set(oldlabor, 'timestampSVSLSITM', oldCopyTimeClockData.timestampSVSLSITM);
    Vue.set(oldOldLabor, 'timestampSVSLSITM', oldCopyTimeClockData.timestampSVSLSITM);
    Vue.set(oldlabor, 'jobLaborTimeClock', oldCopyTimeClockData);
    Vue.set(oldOldLabor, 'jobLaborTimeClock', oldCopyTimeClockData);

    // setting the time stamp for all the impacted records throught the "current" object in state
    // this was the agreed upon solution to fix saving the service order or navigating away
    // from the service order and receiving errors because the DB and State objects were out
    // of sync any time a punch in/out/complete was executed
    let currentSo = state[state.estimorder];
    Vue.set(currentSo, 'timestampSVSLS', newCopyTimeClockData.timestampSVSLS);
    let currentJob = currentSo.jobs[payload.jobKey];
    let currentOldJob = currentSo.oldJobs[payload.jobKey];
    Vue.set(currentJob, 'timestampSVSLSOPS', newCopyTimeClockData.timestampSVSLSOPS);
    Vue.set(currentOldJob, 'timestampSVSLSOPS', newCopyTimeClockData.timestampSVSLSOPS);
    let currentLabor = currentJob.details.laborLines[payload.lbrIndex];
    let currentOldLabor = currentOldJob.details.laborLines[payload.lbrIndex];
    Vue.set(currentLabor, 'timestampSVSLSITM', newCopyTimeClockData.timestampSVSLSITM);
    Vue.set(currentOldLabor, 'timestampSVSLSITM', newCopyTimeClockData.timestampSVSLSITM);
    Vue.set(currentLabor, 'jobLaborTimeClock', newCopyTimeClockData);
    Vue.set(currentOldLabor, 'jobLaborTimeClock', newCopyTimeClockData);

    if (currentLabor.jobLaborTimeClock.isComplete) {
      Vue.set(oldlabor, 'complete', true);
      Vue.set(currentLabor, 'complete', true);
      for (let job of Object.values(currentSo.jobs)) {
        for (let changeLabor of Object.values(job.details.laborLines)) {
          if (changeLabor.itemId != currentLabor.itemId) {
            let latestClockData = currentLabor.jobLaborTimeClock;
            let loopClockData = changeLabor.jobLaborTimeClock;
            if (
              latestClockData.isInAttendance &&
              !latestClockData.isPunchedIn &&
              loopClockData.isAssignedTech &&
              !loopClockData.isComplete &&
              !loopClockData.canStart
            ) {
              Vue.set(loopClockData, 'canStart', true);
            }
          }
        }
      }
    } else {
      for (let job of Object.values(currentSo.jobs)) {
        for (let changeLabor of Object.values(job.details.laborLines)) {
          if (changeLabor.itemId != currentLabor.itemId) {
            let timeClockData = changeLabor.jobLaborTimeClock;
            if (
              timeClockData.isAssignedTech &&
              !timeClockData.isComplete &&
              timeClockData.canStart != currentLabor.jobLaborTimeClock.canStart
            ) {
              Vue.set(timeClockData, 'canStart', currentLabor.jobLaborTimeClock.canStart);
              let oldLabor = oldSo.jobs[
                '{"jobId":"' + job.jobId + '","operationId":' + job.operationId + '}'
              ].details.laborLines.filter(x => x.itemId == changeLabor.itemId);
              if (oldLabor.length == 1) {
                let oldLaborTimeClock = oldLabor[0].jobLaborTimeClock;
                Vue.set(oldLaborTimeClock, 'canStart', currentLabor.jobLaborTimeClock.canStart);
              }
            }
          }
        }
      }
    }
  },
  [_mutations.RESET_JOB_LABOR_TECH_REMARKS](state, payload) {
    let currentTimeClock =
      state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.lbrIndex].jobLaborTimeClock;
    let oldTimeClock =
      state['old' + state.estimorder].jobs[payload.jobKey].details.laborLines[payload.lbrIndex].jobLaborTimeClock;
    Vue.set(currentTimeClock, 'techRemarks', oldTimeClock.techRemarks);
  },
  [_mutations.ADD_LABOR_LINE](state, jobKey) {
    const job = state[state.estimorder].jobs[jobKey];
    if (!job.details.laborLines) job.details.laborLines = [];
    var laborLine = { ...Cloner.shallowClone(job.laborCode), _local: true };
    laborLine.jobLaborTimeClock = JOBLABORTIMECLOCK;
    job.details.laborLines.push(laborLine);
  },
  [_mutations.REMOVE_LABOR_LINE](state, payload) {
    const job = state[state.estimorder].jobs[payload.jobKey];
    const labor = job.details.laborLines[payload.index];
    if (labor._local) {
      job.details.laborLines.splice(payload.index, 1);
    } else {
      Vue.set(labor, 'toBeDeleted', true);
    }
  },
  [_mutations.SET_LABOR_CODE](state, payload) {
    const labor = state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.index];
    Vue.set(labor, 'laborCode', payload.laborCode);
  },
  [_mutations.SET_LABOR_DESCRIPTION](state, payload) {
    const labor = state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.index];
    Vue.set(labor, 'description', payload.description);
  },
  [_mutations.SET_TECH](state, payload) {
    const laborLine = state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.index];
    Vue.set(laborLine, 'technician', payload.technician);
    Vue.set(laborLine, '_showClock', false);
    const jobLaborTimeClock = laborLine.jobLaborTimeClock;
    Vue.set(jobLaborTimeClock, 'showJobLaborTimeClock', false);
  },
  [_mutations.SET_LABOR_PRICE](state, payload) {
    const labor = state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.index];
    Vue.set(labor, 'price', payload.price);
  },
  [_mutations.SET_LABOR_SUGGESTED_PRICE](state, payload) {
    const labor = state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.index];
    Vue.set(labor, 'suggestedPrice', payload.suggestedPrice);
  },
  [_mutations.SET_HOURS_BILL](state, payload) {
    const labor = state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.index];
    Vue.set(labor, 'hoursBill', payload.hoursBill);
  },
  [_mutations.SET_HOURS_FLAT](state, payload) {
    const labor = state[state.estimorder].jobs[payload.jobKey].details.laborLines[payload.index];
    Vue.set(labor, 'hoursFlat', payload.hoursFlat);
  },
  // Parts
  [_mutations.ADD_PART](state, jobKey) {
    const job = state[state.estimorder].jobs[jobKey];
    if (!job.details.parts) job.details.parts = [];
    job.details.parts.push({
      partNumber: null,
      description: null,
      price: null,
      quantityOnHand: null,
      qty: null,
      _local: true,
      _searchingParts: false,
      _loadingPart: false
    });
  },
  [_mutations.REMOVE_PART](state, payload) {
    const job = state[state.estimorder].jobs[payload.jobKey];
    const part = job.details.parts[payload.index];
    if (part._local) {
      job.details.parts.splice(payload.index, 1);
    } else {
      Vue.set(part, 'toBeDeleted', true);
    }
  },
  [_mutations.SET_PART](state, payload) {
    const parts = state[state.estimorder].jobs[payload.jobKey].details.parts;
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    Vue.set(parts, payload.partIndex, { ...part, ...payload.part });
  },
  [_mutations.SET_REQUESTED_PART](state, requestedPart) {
    state.requestedPart = requestedPart;
  },
  [_mutations.SET_PART_SEARCH](state, payload) {
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    Vue.set(part, '_searchingParts', payload.value);
  },
  [_mutations.TOGGLE_PART_LOADING](state, payload) {
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    Vue.set(part, '_loadingPart', !part._loadingPart);
  },
  [_mutations.SET_PART_DESCRIPTION](state, payload) {
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    Vue.set(part, 'description', payload.description);
  },
  [_mutations.SET_PART_QUOTE](state, payload) {
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    Vue.set(part, 'price', payload.price);
    Vue.set(part, 'cost', payload.cost);
    Vue.set(part, 'isWarrantyAlert', payload.isWarrantyAlert);
  },
  [_mutations.SET_AVAILABLE_PARTS](state, payload) {
    state.availableParts = payload;
  },
  [_mutations.SET_PART_QTY_ON_HAND](state, payload) {
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    Vue.set(part, 'quantityOnHand', payload.quantityOnHand);
  },
  [_mutations.SET_PART_QTY](state, payload) {
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    Vue.set(part, 'quantityRequested', payload.quantityRequested);
  },
  [_mutations.SET_PART_PRICE](state, payload) {
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    Vue.set(part, 'price', payload.price);
  },
  [_mutations.SET_PART_MARGIN](state, payload) {
    const part = state[state.estimorder].jobs[payload.jobKey].details.parts[payload.partIndex];
    if (part.price == null || part.price == 0 || part.cost == null || part.cost == 0) {
      Vue.set(part, 'margin', 'N/A');
    } else {
      Vue.set(part, 'margin', `${Math.floor((1 - part.cost / part.price) * 100)}%`);
    }
  },
  // Sublets
  [_mutations.ADD_SUBLET_LINE](state, jobKey) {
    const job = state[state.estimorder].jobs[jobKey];
    if (!job.details.subletLines) job.details.subletLines = [];
    job.details.subletLines.push({ subletCodeId: null, description: null, price: null, _local: true });
  },
  [_mutations.REMOVE_SUBLET_LINE](state, payload) {
    const job = state[state.estimorder].jobs[payload.jobKey];
    const sublet = job.details.subletLines[payload.index];
    if (sublet._local) {
      job.details.subletLines.splice(payload.index, 1);
    } else {
      Vue.set(sublet, 'toBeDeleted', true);
    }
  },
  [_mutations.SET_SUBLET_CODE](state, payload) {
    const sublet = state[state.estimorder].jobs[payload.jobKey].details.subletLines[payload.index];
    sublet.subletCodeId = payload.subletCodeId;
    Vue.set(sublet, 'description', payload.description);
    Vue.set(sublet, 'price', payload.price);
    Vue.set(sublet, 'cost', payload.cost);
    Vue.set(sublet, 'quantity', payload.quantity);
  },
  [_mutations.SET_SUBLET_DESCRIPTION](state, payload) {
    const sublet = state[state.estimorder].jobs[payload.jobKey].details.subletLines[payload.index];
    Vue.set(sublet, 'description', payload.description);
  },
  [_mutations.SET_SUBLET_PRICE](state, payload) {
    const sublet = state[state.estimorder].jobs[payload.jobKey].details.subletLines[payload.index];
    Vue.set(sublet, 'price', payload.price);
  },
  [_mutations.SET_SUBLET_COST](state, payload) {
    const sublet = state[state.estimorder].jobs[payload.jobKey].details.subletLines[payload.index];
    Vue.set(sublet, 'cost', payload.subletCost);
  },
  [_mutations.SET_SUBLET_QTY](state, payload) {
    const sublet = state[state.estimorder].jobs[payload.jobKey].details.subletLines[payload.index];
    Vue.set(sublet, 'quantity', payload.quantity);
  },
  [_mutations.SET_SUBLET_SEARCH](state, payload) {
    const sublet = state[state.estimorder].jobs[payload.jobKey].details.subletLines[payload.subletIndex];
    Vue.set(sublet, '_searchingSublets', !sublet._searchingSublets);
  },
  // Misc
  [_mutations.ADD_MISC_LINE](state, jobKey) {
    const job = state[state.estimorder].jobs[jobKey];
    if (!job.details.miscLines) job.details.miscLines = [];
    job.details.miscLines.push({ miscCode: null, description: null, price: null, _local: true });
  },
  [_mutations.REMOVE_MISC_LINE](state, payload) {
    const job = state[state.estimorder].jobs[payload.jobKey];
    const misc = job.details.miscLines[payload.index];
    if (misc._local) {
      job.details.miscLines.splice(payload.index, 1);
    } else {
      Vue.set(misc, 'toBeDeleted', true);
    }
  },
  [_mutations.SET_MISC_CODE](state, payload) {
    const misc = state[state.estimorder].jobs[payload.jobKey].details.miscLines[payload.index];
    misc.miscCode = payload.miscCode;
    Vue.set(misc, 'description', payload.description);
    Vue.set(misc, 'price', payload.price);
    Vue.set(misc, 'quantity', payload.quantity);
  },
  [_mutations.SET_MISC_DESCRIPTION](state, payload) {
    const misc = state[state.estimorder].jobs[payload.jobKey].details.miscLines[payload.index];
    Vue.set(misc, 'description', payload.description);
  },
  [_mutations.SET_MISC_PRICE](state, payload) {
    const misc = state[state.estimorder].jobs[payload.jobKey].details.miscLines[payload.index];
    Vue.set(misc, 'price', payload.price);
  },
  [_mutations.SET_MISC_QTY](state, payload) {
    const misc = state[state.estimorder].jobs[payload.jobKey].details.miscLines[payload.index];
    Vue.set(misc, 'quantity', payload.quantity);
  },
  [_mutations.SET_MISC_SEARCH](state, payload) {
    const misc = state[state.estimorder].jobs[payload.jobKey].details.miscLines[payload.miscIndex];
    Vue.set(misc, '_searchingMisc', !misc._searchingMisc);
  },
  // Comments
  [_mutations.ADD_COMMENT_LINE](state, jobKey) {
    const job = state[state.estimorder].jobs[jobKey];
    if (!job.details.commentLines) job.details.commentLines = [];
    job.details.commentLines.push({ commentCodeId: null, description: null, _local: true });
  },
  [_mutations.REMOVE_COMMENT_LINE](state, payload) {
    const job = state[state.estimorder].jobs[payload.jobKey];
    const comment = job.details.commentLines[payload.index];
    if (comment._local) {
      job.details.commentLines.splice(payload.index, 1);
    } else {
      Vue.set(comment, 'toBeDeleted', true);
    }
  },
  [_mutations.SET_COMMENT_CODE](state, payload) {
    const comment = state[state.estimorder].jobs[payload.jobKey].details.commentLines[payload.index];
    comment.commentCodeId = payload.commentCodeId;
    comment.description = payload.description;
  },
  [_mutations.SET_COMMENT_TEXT](state, payload) {
    const comment = state[state.estimorder].jobs[payload.jobKey].details.commentLines[payload.index];
    comment.description = payload.value;
  },
  [_mutations.SET_COMMENT_SEARCH](state, payload) {
    const comment = state[state.estimorder].jobs[payload.jobKey].details.commentLines[payload.commentIndex];
    Vue.set(comment, '_searchingComments', !comment._searchingComments);
  },
  [_mutations.SET_VMRS_CODE](state, payload) {
    Vue.set(state[state.estimorder].jobs[payload.jobKey], 'vmrsCode', payload.vmrsCodeByJob);
  },
  // Inspections
  [_mutations.ADD_INSPECTION](state, jobKey) {
    const job = state[state.estimorder].jobs[jobKey];
    if (!job.details.inspections) job.details.inspections = [];
    job.details.inspections.push({ inspectionId: null, name: null, _local: true });
  },
  [_mutations.REMOVE_INSPECTION](state, payload) {
    const job = state[state.estimorder].jobs[payload.jobKey];
    const inspection = job.details.inspections[payload.index];
    if (inspection._local) {
      job.details.inspections.splice(payload.index, 1);
    } else {
      Vue.set(inspection, 'toBeDeleted', true);
    }
  },
  [_mutations.SET_INSPECTION](state, payload) {
    const inspection = state[state.estimorder].jobs[payload.jobKey].details.inspections[payload.index];
    inspection.inspectionId = payload.inspectionId;
    inspection.name = payload.name;
  },

  // Asides
  //Customer Notes from Excede
  [_mutations.SET_CUSTOMER_ALERTS](state, payload) {
    state.customerAlerts = payload;
  },
  [_mutations.SET_CUSTOMER_ALERT](state, payload) {
    state.customerAlert = payload;
  },
  [_mutations.SET_BILL_TO_CUSTOMER_ALERTS](state, payload) {
    state.billToCustomerAlerts = payload;
  },
  [_mutations.SET_BILL_TO_CUSTOMER_ALERT](state, payload) {
    state.billToCustomerAlert = payload;
  },
  [_mutations.SET_ATTACHMENTS](state, attachments) {
    state[state.estimorder].attachments = Cloner.deepClone(attachments);
    state['old' + state.estimorder].attachments = Cloner.deepClone(attachments);
  },
  [_mutations.SET_BRANCH_SPECIFIC_MISC_CODES](state, branchSpecificMiscCodes) {
    state.branchSpecificMiscCodes = branchSpecificMiscCodes;
  },
  [_mutations.SET_CUSTOMER_SPECIFIC_REPAIR_REASONS](state, customerSpecificRepairReasons) {
    state.customerSpecificRepairReasons = customerSpecificRepairReasons;
  },
  [_mutations.SET_APPOINTMENT_UNIT_SERVICE_ORDERS](state, appointmentUnitServiceOrders) {
    state.appointmentUnitServiceOrders = appointmentUnitServiceOrders;
  },
  [_mutations.SET_AUDIT_RUN_ERROR](state, value) {
    state[state.estimorder].auditRunError = value;
  },
  [_mutations.SET_IS_OPEN_SERVICE_ORDER](state, value) {
    state.isOpenServiceOrder = value;
  },
  [_mutations.SET_REPAIR_CONTACT](state, repairContact) {
    state[state.estimorder].repairContact = repairContact;
  },
  // Lookups
  [_mutations.SET_LABOR_CODES_LIST](state, laborCodesList) {
    state.laborCodesList = laborCodesList;
  },
  [_mutations.SET_PART_LIST](state, partList) {
    state.partList = partList;
  },
  [_mutations.SET_TECH_LIST](state, techList) {
    state.techList = techList;
  }
};
