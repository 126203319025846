import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, AIActions, AIMutations } from './types';
import AIService from '@/shared/services/AIService';
const _actions = namespaceHelper.removeNamespace(namespace, AIActions);
const _mutations = namespaceHelper.removeNamespace(namespace, AIMutations);

export default {
  async [_actions.GENERATE_THREE_CS]({ commit }, params) {
    const result = await AIService.generateThreeCs(params);
    await commit(_mutations.SET_GENERATED_COMPLAINT, result.data.generatedComplaint);
    await commit(_mutations.SET_GENERATED_CAUSE, result.data.generatedCause);
    await commit(_mutations.SET_GENERATED_CORRECTION, result.data.generatedCorrection);
  }
};
